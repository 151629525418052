import React from "react";
import NavBar from "./NavBar";
// import Footer from "./Footer";

import { Helmet } from "react-helmet";

export default function Layout({ children }) {
  return (
    <>
      <Helmet
        meta={[
          {
            name: "theme-color",
            content: "#2f4f4f",
          },
        ]}
      ></Helmet>
      <NavBar />
      {children}
      {/* <Footer /> */}
    </>
  );
}
