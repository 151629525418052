import React from "react";
import { useState } from "react";
import { css, jsx } from "@emotion/react";

const burgerContainer = css`
  margin-left: auto;
  background: transparent;
  height: max-content;
  border: none;
  cursor: pointer;
`;

const burgerBarTop = ({ toggle }) => css`
  width: 25px;
  height: 3px;
  background-color: ivory;
  margin: 10px 0;
  transition: 0.3s;
  ${toggle === true &&
  `
    -webkit-transform: rotate(-45deg) translate(-4px, 5px);
    transform: rotate(-45deg) translate(-4px, 5px);
`}
`;

const burgerBarBottom = ({ toggle }) => css`
  width: 25px;
  height: 3px;
  background-color: ivory;
  margin: 10px 0;
  transition: 0.3s;

  ${toggle === true &&
  `
    -webkit-transform: rotate(45deg) translate(-4px, -5px);
    transform: rotate(45deg) translate(-4px, -5px);
`}
`;

export const BurgerToggle = ({ toggle, parentCallback }) => {
  const handleClick = () => {
    parentCallback(!toggle);
  };
  return (
    <button css={burgerContainer} onClick={handleClick}>
      <div css={burgerBarTop({ toggle })}></div>
      <div css={burgerBarBottom({ toggle })}></div>
    </button>
  );
};
