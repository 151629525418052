import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link } from "gatsby";
import { css, jsx } from "@emotion/react";
import { useWindowSize } from "../hooks/hooks";
import { BurgerToggle } from "./Burger";
import { FocusableComponent } from "./FocusableComponent";

// const header = ({ menuToggle, width }) =>
//   css`
//     will-change: transform;
//     background-color: darkslategray;
//     padding: 8px;
//     display: flex;
//     flex-flow: row;
//     ${width < 300 ? `font-size: 20px;` : `font-size: 24px;`}
//     overflow: hidden;
//     justify-content: space-between;
//     ${menuToggle
//       ? `
//     max-height: 200px;
//     transition: max-height 0.3s ease-in
//   `
//       : `max-height: 54px;
// transition: max-height 0.3s ease-out`}
//   `;

const header = ({ menuToggle }) => css`
  background-color: darkslategray;
  display: flex;
  flex-flow: row;
  font-size: 24px;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  height: 48px;
`;

const homelink = css`
  margin-right: auto;
  display: inline-block;
  white-space: nowrap;
  height: max-content;
  color: ivory;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    text-decoration: underline;
  }
`;

const linksrow = css`
  display: flex;
  list-style: none;
  flex-flow: row;
  align-items: center;
  color: ivory;
  justify-content: space-around;
  width: 50%;
  font-size: 24px;
`;

const linkscol = ({ menuToggle }) => css`
  display: flex;
  margin: 0px;
  overflow: hidden;
  flex-direction: column;
  flex-wrap: none;
  align-items: end;
  padding-right: 16px;
  font-size: 32px;
  background-color: darkslategray;
  ${menuToggle
    ? `
    height: 140px;
    -webkit-transition: height 0.2s ease-in;
    transition: height 0.2s ease-in;
`
    : `height: 0px;
    -webkit-transition: height 0.2s ease-out;
    transition: height 0.15s ease-out;`}
`;

const link = ({ menuToggle }) => css`
  color: ivory;
  display: block;
  font-weight: normal;
  padding-bottom: 4px;
  text-decoration: none;
  font-family: "Times New Roman", Times, serif;
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    text-decoration: underline;
  }
`;

export default function NavBar() {
  const windowSize = useWindowSize();
  const [menuToggle, setMenuToggle] = useState(false);
  const ref = useRef();
  const [hasFocus, setFocus] = useState(false);

  useEffect(() => {
    if (
      ref.current !== undefined &&
      document.hasFocus() &&
      ref.current.contains(document.activeElement)
    ) {
      setFocus(true);
    }
  }, []);

  const toggleCallback = useCallback((toggle) => {
    setMenuToggle(toggle);
  }, []);

  const menuItemFocus = () => {
    setFocus(true);
    setMenuToggle(true);
  };

  if (windowSize.width < 640) {
    return (
      <header>
        <nav css={header({ menuToggle: menuToggle })}>
          <Link
            css={homelink}
            aria-label="logo"
            role="img"
            to="/"
            onClick={() => setMenuToggle(false)}
          >
            💻 nevin.dev
          </Link>
          <BurgerToggle
            toggle={menuToggle}
            parentCallback={toggleCallback}
          ></BurgerToggle>
        </nav>
        <ul css={linkscol({ menuToggle: menuToggle })}>
          {/* <Link
            to="/about"
            onClick={() => setMenuToggle(false)}
            activeStyle={{ color: "goldenrod" }}
            css={link({ menuToggle: menuToggle })}
            ref={ref}
            onFocus={menuItemFocus}
            onBlur={() => setFocus(false)}
          >
            About
          </Link> */}
          <Link
            to="/projects"
            onClick={() => setMenuToggle(false)}
            activeStyle={{ color: "goldenrod" }}
            css={link({ menuToggle: menuToggle })}
            ref={ref}
            onFocus={menuItemFocus}
            onBlur={() => setFocus(false)}
          >
            Projects
          </Link>
          <Link
            to="/posts"
            onClick={() => setMenuToggle(false)}
            activeStyle={{ color: "goldenrod" }}
            css={link({ menuToggle: menuToggle })}
            ref={ref}
            onFocus={menuItemFocus}
            onBlur={() => setFocus(false)}
          >
            Posts
          </Link>
          <Link
            to="/resume"
            activeStyle={{ color: "goldenrod" }}
            css={link({ menuToggle: menuToggle })}
          >
            Resumé
          </Link>
        </ul>
      </header>
    );
  }
  return (
    <header>
      <nav css={header({ menuToggle: menuToggle })}>
        <Link css={homelink} aria-label="logo" role="img" to="/">
          💻 nevin.dev
        </Link>
        <ul css={linksrow}>
          {/* <Link
            to="/about"
            activeStyle={{ color: "goldenrod" }}
            css={link({ menuToggle: menuToggle })}
          >
            About
          </Link> */}
          <Link
            to="/projects"
            activeStyle={{ color: "goldenrod" }}
            css={link({ menuToggle: menuToggle })}
          >
            Projects
          </Link>
          <Link
            to="/posts"
            activeStyle={{ color: "goldenrod" }}
            css={link({ menuToggle: menuToggle })}
          >
            Posts
          </Link>
          <Link
            to="/resume"
            activeStyle={{ color: "goldenrod" }}
            css={link({ menuToggle: menuToggle })}
          >
            Resumé
          </Link>
        </ul>
      </nav>
    </header>
  );

  // <ul css={linkscol}>
  //   <Link css={link}>About</Link>
  //   <Link css={link}>Projects</Link>
  //   <Link css={link}>Posts</Link>
  // </ul>

  return (
    <nav css={header({ menuToggle: menuToggle, width: windowSize.width })}>
      <Link css={homelink} aria-label="logo" role="img" to="/">
        💻 nevin.dev
      </Link>
      {windowSize.width < 576 ? (
        <BurgerToggle
          toggle={menuToggle}
          parentCallback={toggleCallback}
        ></BurgerToggle>
      ) : (
        <ul css={linksrow}>
          <Link activeStyle={{ color: "goldenrod" }} css={link} to="/about">
            About
          </Link>
          <Link activeStyle={{ color: "goldenrod" }} css={link} to="/projects">
            Projects
          </Link>
          <Link activeStyle={{ color: "goldenrod" }} css={link} to="/posts">
            Posts
          </Link>
        </ul>
      )}
    </nav>
  );
}
